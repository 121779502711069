.programovy-rozpocet {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  text-align: left;
  /* horny okraj rovny vyske titulku */
  margin: 64px 0 0 0;
  padding: 0.0em 0.0em;
}

.programovy-rozpocet-info-text {
  padding: 0.2em 0  0.2em 1em;
  margin: 0.6em;
  color: #5a5a5a;
  background-color: orange;
  border-radius: 0.4em;
}
