.row-detail-wrapper {
  /* daj titulok vlavo a cisla vpravo */
  justify-content: space-between;
  /* natiahni sirku elementov na celu nevyplnenu sirku */
  flex-grow: 1;
  /* nedovol pretiect pri malich sirkach obrazovky cez pravy okraj TreeView */
  overflow: 'auto';
}

/* stlpec title */
.row-detail-title-wrapper {
  width: 100%;
  margin-top: 0em;
}

.row-detail-title-pointer {
  /* tvar kurzora nad title: pointer/default */
  cursor: default;
}

.row-detail-numbers-group {
  /* odsad posledny stlpec od kraja  */
  margin-right: 0.6em;
  /* blok s cislami na pravo */
  justify-content: flex-end;
  /* natiahni sirku elementov na celu nevyplnenu sirku */
  flex-grow: 1;
}

/* stlpec r-schval */
.row-detail-r-schval-wrapper {
  text-align: right;
  min-width: 9em;
  /* padding-top: 0.2em; */
}

/* stlpec r-uprav */
.row-detail-r-uprav-wrapper {
  text-align: right;
  min-width: 9em;
  /* padding-top: 0.2em; */
}

/* stlpec r-skut */
.row-detail-r-skut-wrapper {
  text-align: right;
  min-width: 9em;
  /* padding-top: 0.2em; */
}

/* stlpec percento-plnenia */
.row-detail-percento-plnenia-wrapper {
  text-align: right;
  min-width: 5em;
  /* padding-top: 0.2em; */
}

.row-detail {
  /* prevzatie pisma z rodica */
  font-weight: inherit;
  white-space: nowrap;
}

.first-line-indent {
  text-indent: -2em;
  /* Odsadenie všetkých riadkov okrem prveho*/
  padding-left: 2.3em; 
  /* prevzatie bold pisma z rodica */
  font-weight: inherit;
  display: inline-block;
}

/* text s poctom vyskytov L9 zaznamov */
.supplement-text {
  padding-left: 0.2em;
  color: #cd5c5c;
}

/* zvyrazneny text pre fullsearch */
.row-detail-searched-text {
  background-color: darkorange;
}