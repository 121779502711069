/* blok s vyberom levelu a tlacidlami rozbal/zbal */
.tree-view-controll-wrapper {
  padding-left: 1em;
  padding-right: 1em;
}

.tree-view-row-detail-wrapper {
  /* daj ikonu vlavo a obsah vpravo */
  justify-content: space-between;
  /* natiahni ikonu do na celu vysku deti */
  align-items: stretch;
}

/* tvar kurzora nad ikonou rozbal/zbal: pointer/default */
.tree-view-row-detail-icon {
  cursor: pointer;
  width: "30px";
  min-width: "30px";
}

/* tvar kurzora nad ikonou [X]: no-drop */
.tree-view-end-icon {
  cursor: no-drop;
}

/* titulok tabulky Príjmy/Výdavky/Príjmy-Výdavky */
.tree-view-title {
  color: #013e5f;
  font-weight: bold;
  background-color: #d1ddfa;
  border-bottom: 2px solid #b2c8fb;
  /* prichytenie headera k vrchu okna pri scrolovani  */
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky;
  /* rovne vyske titulku v datamesta*/
  top: 64px;
  border-radius: 0.5em 0.5em 0 0;
}

/* Levely */
.l1 {
  color: #000000;
  background-color: #eef3ff;
  padding-top: 0.1em;
}

/* L1 pre sumar prijmy-vydaje */
.l1-pv {
  color: #000000;
  background-color: #eef3ff;
  padding-bottom: 0.3em;
  padding-top: 0.2em;
}

.l2 {
  color: #393b19;
  background-color: #e1e3bd;
}

.l3 {
  color: #337841;
  background-color: #bde3c5;
}

.l4 {
  color: #328344;
  background-color: #cdecd4;
}

.l5 {
  color: #308d46;
  background-color: #d9f2df;
}

.l6 {
  color: #285a89;
  background-color: #c2d8ed;
}

.l7 {
  color: #315c85;
  background-color: #cedeed;
}

.l8 {
  color: #365d87;
  background-color: #DCE6F1;
}

.l9 {
  color: #1b1b1b;
  background-color: #fafafa;
  border-bottom: 1px solid #d6d6d6;
  margin-left: 0px;
  padding-left: 0.4em;
  padding-top: 0.0em;
  padding-bottom: 0.0em;
  min-height: 1.5em;
}