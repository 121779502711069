@keyframes pulse {
  0% {
    transform: scale(1);
  }

  30% {
    transform: scale(1);
  }

  60% {
    transform: scale(1.4);
  }

  100% {
    transform: scale(1);
  }
}

.env-banner-floating {
  display: flex;
  position: fixed;
  z-index: 9999;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: rgba(255, 145, 0, 0.8);
  outline-style: solid;
  outline-color: rgba(255, 145, 0, 0.3);
  border-radius: 50%;
  user-select: none;
  cursor: grab;
}

.env-banner-global {
  pointer-events: none;
  // height: 100%;
  width: 84%;
  left: calc((100% - 84%)/2); //na stred podla width
  top: 4px;
  position: fixed;
  overflow: hidden;
  z-index: 9999;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  background-color: rgba(255, 145, 0, 0.6);
  outline: 0.2rem solid rgba(255, 145, 0, 0.3);
  padding: 4px;
}

.env-banner-top {
  pointer-events: none;
  width: 90%;
  left: calc((100% - 90%)/2); //na stred podla width
  overflow: hidden;
  z-index: 9999;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  background-color: rgba(255, 145, 0, 0.6);
  outline: 0.2rem solid rgba(255, 145, 0, 0.3);
  padding: 4px;
  margin-bottom: 2px;
}

.env-banner-text-desktop {
  color: rgba(0, 0, 0, 0.95);
  padding-right: 4px;
  font-size: small;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.env-banner-text-mobile {
  color: rgba(0, 0, 0, 0.95);
  padding-right: 4px;
  font-size: x-small;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.env-banner-text-inline {
  display: inline;
  color: rgba(0, 0, 0, 0.95);
}