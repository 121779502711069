.mapTooltip {
  padding: 0px 6px;
  text-align:center;
}

.leaflet-container a.leaflet-popup-close-button{
  position: absolute;
	top: 0;
	right: 0;
	padding: 0px 12px 0 0;
	border: none;
	text-align: center;
	width: 36px;
	height: 28px;
	font: 32px/28px Tahoma, Verdana, sans-serif;
	color: #000;
	text-decoration: none;
	font-weight: bold;
	background: transparent;
}

