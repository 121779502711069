:root {
  --size: 28px;
  --font-size: 28px;
  --duration: 3s;
  --timing: ease;
  --bg-color: #343434;
  --color: #343434;
  --info-bg-color: white;
}

* {
  margin: 0;
  padding: 0;
}

.parent {
  width: 42px;
  height: 42px;

  display: flex;
  justify-content: center;
  align-items: center;

  background: var(--bg-color);
}

.info {
  position: relative;
  background-color: var(--color);

  display: flex;
  justify-content: center;
  align-items: center;

  width: var(--size);
  height: var(--size);

  text-align: center;
  line-height: var(--size);
  border-radius: 50%;

  /* animation: beat var(--duration) var(--timing) 0s infinite; */
  /* animation: pulse var(--duration) infinite cubic-bezier(0.66, 0.33, 0, 1); */
}

.info span {
  font-size: var(--font-size);
  color: var(--bg-color);
  /* text-shadow: 0px 0px 10px hsla(0, 0%, 100%, 0.4); */
  /* text-shadow: 0px 0px 10px rgba(255, 255, 255, 0.4); */
}


.info::before,
.info::after {
  display: block;
  content: "";
  position: absolute;

  width: 100%;
  height: 100%;
  top: 1;
  left: 1;

  background-color: var(--info-bg-color);
  opacity: 0.2;
  border-radius: inherit;
  border:  2px solid rgb(96, 86, 86);
  /* border:  2px dotted #343434; */
}

.info::before {
  /* z-index: -3; */
  /* animation: beat-before var(--duration) var(--timing) 100ms infinite; */
  /* display: none;  */
}

.info::after {
  z-index: -2;
  animation: beat-after var(--duration) var(--timing) 1000ms infinite;
  /* display: none;  */
}


@keyframes beat {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }
}

@keyframes beat-before {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.4);
  }
}

@keyframes beat-after {
  0% {
    transform: scale(1);
  }

  33% {
    transform: scale(1.6);
  }

  66% { 
    transform: scale(1); /* Návrat na pôvodný stav */
  }
}

.noAnimation {
  animation: none;
}

.noAnimation:before,
.noAnimation:after {
  content: none;
}