.control-wrapper {
  flex-grow: 1;
  justify-content: space-between;
  width: 100%;
}

.filter-wrapper {
  flex-grow: 1;
  justify-content: flex-start;
  width: 100%;
}