body {
  margin: 0;
  padding: 0;
  background-color: var(--body-bg) !important;
}

.container {
  max-width: 700px;
  margin: 0 auto;
  /* padding: 0 1.25rem; */
}

.zstContainer {
  max-width: 80%;
}

.container.public-zone {
  max-width: 960px;
  margin: 0 auto;
  padding: 0 0.9375rem;
}

.container.w-padding {
  padding: 0 0.9375rem;
}

.container.container-fluid {
  max-width: 100%;
}

.container.grid-container {
  width: 100%;
  max-width: 100%;
}

@media(min-width: 1200px) {
  .container.grid-container {
    width: 90%;
  }
}

.container.w-margin {
  margin-top: 56px !important;
}

@media(min-width: 600px) {
  .container.w-margin {
    margin-top: 64px !important;
  }
}

.miniContainer {
  width: 100%;
  max-width: 400px;
  margin: 0px auto 32px auto;
}

.content {
  padding: 8px;
  margin: 8px;

}

.content h3 {
  text-align: center;
}

.button {
  margin-top: 8px;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.attachments {
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}


.stop-scrolling {
  height: 100%;
  overflow: hidden;
}