.pr-main-title {
  color: #1A237E;
  padding-left: 1em;
}

.pr-filters {
  background-color: #eef3ff;
  padding: 2em 1em;
  border: 1px solid #a9e4de;
  border-radius: 0.5em 0.5em 0 0;
}

.pr-incomes-wrapper {
  margin-top: 1em;
  border: 1px solid #a9e4de;
  border-top: 2px solid #a9e4de;
  padding-bottom: 0em;
}

.pr-expanses-wrapper {
  margin-top: 2em;
  border: 1px solid #a9e4de;
  border-top: 2px solid #a9e4de;
  padding-bottom: 0em;
}

.pr-inexp-wrapper {
  margin-top: 2em;
  margin-bottom: 1em;
  border: 1px solid #a9e4de;
  border-top: 2px solid #a9e4de;
  padding-bottom: 0em;
}

.pr-incomes-title-wrapper {
  background-color: #E0F2F1;
  padding-left: 1em;
  padding-right: 1em;
  border-radius: 0 0 0.5em 0.5em;
}

.pr-expanses-title-wrapper {
  background-color: #E0F2F1;
  padding-left: 1em;
  padding-right: 1em;
  border-radius: 0 0 0.5em 0.5em;
}

.pr-inexp-title-wrapper {
  background-color: #E0F2F1;
  padding-left: 1em;
  padding-right: 1em;
  border-radius: 0 0 0.5em 0.5em;
}

.pr-incomes-tree-view-wrapper {
  padding-top: 0.2em;
  background-color: #FFFFFF;
}

.pr-expanes-tree-view-wrapper {
  padding-top: 0.2em;
  background-color: #FFFFFF;
}

.pr-inexp-tree-view-wrapper {
  padding-top: 1em;
  background-color: #FFFFFF;
}

.pr-date {
  color: #757575;
}

.pr-incomes-title {
  color: #1A237E;
  padding: 0.4em 0 0.4em 0;
}

.pr-expanses-title {
  color: #1A237E;
  padding: 0.4em 0 0.4em 0;
}

.pr-inexp-title {
  color: #1A237E;
  padding: 0.4em 0 0.4em 0;
}

.no-wrap {
  white-space: nowrap
}